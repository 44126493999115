import { navigate } from "gatsby";
import * as React from "react";
import { connect } from "react-redux";
import Button from "src/components/Button";
import Typography from "src/components/Typography";
import { actions as ratingsActions } from "src/store/Rating";
import { VideoRating } from "src/types";
import style from "./RatingForm.module.css";

interface ConnectStateProps {
  ratings: VideoRating[];
}

interface ConnectDispatchProps {
  setRating: typeof ratingsActions.setRating;
}

interface Props extends ConnectDispatchProps, ConnectStateProps {
  playlistId: string;
  videoId: string;
  navigateAfterSubmit?: boolean;
  inline?: boolean;
}

interface State {
  value?: number;
}

class RatingForm extends React.Component<Props, State> {
  public state = {
    value: null,
  };

  public render() {
    const { props } = this;
    return (
      <div
        className={[
          style.ratingWrapper,
          props.inline && style.lineWrapper,
        ].join(" ")}
      >
        <div className={style.numbersWrapper}>
          {[...new Array(10)].map((_, i) => (
            <Typography
              key={i}
              type="h4"
              onClick={() => this.setState({ value: i + 1 })}
              style={{
                color: this.state.value === i + 1 && "red",
                cursor: "pointer",
              }}
            >
              {i + 1}
            </Typography>
          ))}
        </div>
        <Button
          className={style.button}
          disabled={!this.state.value}
          handleClick={this.submitData}
        >
          проголосовать
        </Button>
      </div>
    );
  }

  private submitData = async () => {
    const value: number = this.state.value;
    this.props.setRating({
      playlistId: this.props.playlistId,
      videoId: this.props.videoId,
      value: value,
    });

    if (this.props.navigateAfterSubmit) {
      await navigate(window.history?.state?.closeTo || "/playlists", {
        replace: true,
      });
    }
  };
}

const mapActionsToProps = {
  setRating: ratingsActions.setRating,
};

const mapStateToProps = (state) => ({
  ratings: state.ratings.ratings,
});

export default connect<ConnectStateProps, ConnectDispatchProps>(
  mapStateToProps,
  // @ts-ignore
  mapActionsToProps
)(RatingForm);
