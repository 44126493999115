import * as React from "react";
import RatingForm from "src/components/RatingForm";
import Typography from "src/components/Typography";
import styles from "./RatingModal.module.css";

interface Props {
  video?: { name: string; description?: string; _id: string };
  playlistId: string;
  userId: string;
}

export const RatingModal: React.SFC<Props> = (props) => {
  if (!props.video) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <Typography type="boldText">{props.video.name}</Typography>
        <div className={styles.line} />
        <Typography type="boldText">{props.video.description}</Typography>
        <RatingForm
          playlistId={props.playlistId}
          videoId={props.video._id}
          navigateAfterSubmit
        />
      </div>
    </div>
  );
};

export default RatingModal;
