import { Link } from "gatsby";
import React from "react";
import times from "src/images/blackTimes.svg";
import whiteTimes from "src/images/whiteTimes.svg";
import ModalWrapperModuleCss from "./ModalWrapper.module.css";

class ModalComponent extends React.Component<{
  closeTo: string;
  blackpage?: boolean;
  isVideo?: boolean;
}> {
  public componentDidMount() {
    if (window.innerHeight > window.innerWidth) {
      window.scrollTo({ top: 0 });
    }
  }

  public componentWillUnmount() {}

  public render() {
    return (
      <div
        className={[
          ModalWrapperModuleCss.modalContainer,
          this.props.isVideo && ModalWrapperModuleCss.isVideo,
        ].join(" ")}
      >
        <div
          className={`${ModalWrapperModuleCss.modalContent} ${
            this.props.blackpage ? ModalWrapperModuleCss.blackpage : ""
          }`}
        >
          <div className={ModalWrapperModuleCss.childrenWrapper}>
            {this.props.children}
            {this.renderCloseButton()}
          </div>
        </div>
      </div>
    );
  }

  private renderCloseButton() {
    return (
      <div className={ModalWrapperModuleCss.closeButtonWrapper}>
        <Link
          className={ModalWrapperModuleCss.closeButton}
          to={this.props.closeTo}
          state={{
            noScroll: true,
          }}
        >
          <img
            src={this.props.blackpage ? whiteTimes : times}
            alt="close button"
          />
        </Link>
      </div>
    );
  }
}

export default ModalComponent;
