import * as React from "react";
import ModalWrapper from "src/components/ModalWrapper";
import RatingModal from "src/components/RatingModal";
import Seo from "src/components/Seo";
import { PageProps } from "src/types";

const RatingPage = (props: PageProps) => {
  return (
    <ModalWrapper blackpage>
      <Seo pageTitle="Рейтинг для видео" />
      <RatingModal {...props.location.state} />
    </ModalWrapper>
  );
};

export default RatingPage;
