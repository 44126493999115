import { ModalRoutingContext } from "gatsby-plugin-modal-routing";
import React from "react";
import ModalComponent from "./ModalComponent";
import ModalWrapperModuleCss from "./ModalWrapper.module.css";

interface InnerFuncProps {
  modal: boolean;
  closeTo: string;
}

class ModalWrapper extends React.Component<{
  blackpage?: boolean;
  isVideo?: boolean;
}> {
  public render() {
    const { props } = this;
    return (
      <ModalRoutingContext.Consumer>
        {({ modal, closeTo }: InnerFuncProps) => {
          if (modal) {
            return (
              <ModalComponent
                closeTo={closeTo}
                blackpage={this.props.blackpage}
                isVideo={this.props.isVideo}
              >
                {this.props.children}
              </ModalComponent>
            );
          }
          return (
            <div className={ModalWrapperModuleCss.nonModalWrapper}>
              {props.children}
            </div>
          );
        }}
      </ModalRoutingContext.Consumer>
    );
  }
}

export default ModalWrapper;
